import {
  Button,
  Card,
  CardLevel,
  Drawer,
  FormGroup,
  ISelectOption,
  Input,
  PaddingLevel,
  Select,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Company } from '../../../../javascript/models';
import { createToast } from '../../../FlashToaster';
import { DrawerHeader } from '../../../shared/DrawerHeader';

export function EditForm({
  company,
  fetchData,
  handleClose,
}: {
  company: Company;
  fetchData(): void;
  handleClose(): void;
}) {
  const [companyName, setCompanyName] = React.useState<string>(company.name);
  const [selectedMembership, setSelectedMembership] = React.useState<string>(company.membershipStatus);

  const handleNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleMemberSelect = (selectedItem: ISelectOption) => {
    setSelectedMembership(selectedItem.value);
  };

  const memebershipOptions = [
    { value: 'none', label: 'None' },
    { value: 'community', label: 'Community' },
    { value: 'premium', label: 'Premium' },
  ];

  const handleSave = () => {
    company.name = companyName;
    company.membershipStatus = selectedMembership;
    company.save().then((success) => {
      if (success) {
        createToast({ type: 'success', text: 'Company successfully saved' });
        handleClose();
        fetchData();
      } else {
        createToast({ type: 'error', text: 'Unable to save company' });
      }
    });
  };

  const selectedMembershipOption = memebershipOptions.find((option) => option.value === selectedMembership);

  return (
    <Drawer isOpen={true} onClose={handleClose} maxWidth={800}>
      <DrawerHeader title="Edit Organisation" handleClose={handleClose} />
      <div style={{ overflowY: 'scroll' }}>
        <Card
          paddingLevel={PaddingLevel.REGULAR}
          level={CardLevel.NONE}
          cardInnerProps={{ className: 'pad-h-5 pad-v-3' }}
        >
          <FormGroup label="Name">
            <Input type="text" value={companyName} onChange={handleNameChange} />
          </FormGroup>
          <FormGroup label="Membership type">
            <Select
              fill={true}
              buttonProps={{ large: true }}
              items={memebershipOptions}
              onItemSelect={handleMemberSelect}
              activeItem={selectedMembershipOption}
            />
          </FormGroup>
          <Button text="Save" onClick={handleSave} large={true} />
        </Card>
      </div>
    </Drawer>
  );
}
