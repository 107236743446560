import * as React from "react";
import {
  Caption,
  IconButton32,
  IconNames8,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Subheading2,
} from "@pinpointhq/thumbtack";
import { FinalState } from "react-table";

interface IProps extends Partial<FinalState> {
  meta: any;
  pageNumber: number;
}

export default function paginationComponent(props: IProps) {
  if (props.data.length === 0) return null;

  const {
    canNext,
    canPrevious,
    onPageChange,
    onPageSizeChange,
    pageNumber,
    pageSize,
  } = props;
  const meta = props.meta || { total: 0, pages: 1 };

  const menuItems = [10, 20, 50, 100].map((size) => {
    return (
      <MenuItem
        key={size}
        onClick={() => onPageSizeChange(size, 1)}
        text={size}
      />
    );
  });

  // PageNumber is 0 indexed in ReactTable but starts for 1 for Graphiti
  const startNumber = pageSize * pageNumber + 1;
  const endNumber = Math.min(startNumber - 1 + pageSize, meta.total);

  function back() {
    onPageChange(pageNumber - 1);
  }

  function next() {
    onPageChange(pageNumber + 1);
  }

  // const paginationNumbers = Array.from(Array(meta.pages), (item, index) => index + 1);
  // const visiblePaginationNumbers = paginationNumbers.slice(pageNumber, pageNumber + 4).map((number) => {
  //   return <IconButton32>1</IconButton32>;
  // });

  return (
    <div className="pad-v-2 pad-h-3">
      <div className="frow frow--items-center frow--justify-between frow--gutters-2x">
        <div className="frow frow--items-center frow--gutters-2x">
          <div>
            <div className="frow frow--items-center frow--gutters">
              <Caption>Items per page</Caption>
              <Popover>
                <MenuTrigger>
                  <Subheading2 muted={true}>{pageSize}</Subheading2>
                </MenuTrigger>
                <Menu>{menuItems}</Menu>
              </Popover>
            </div>
          </div>
          <Caption muted={true}>
            Showing {startNumber} - {endNumber} of {meta.total}
          </Caption>
        </div>
        <div>
          <div className="frow frow--gutters frow--nowrap">
            <div>
              <IconButton32
                icon={IconNames8.CARET_LEFT}
                onClick={back}
                disabled={!canPrevious}
                title="Previous"
              />
            </div>
            {/*<div>{visiblePaginationNumbers.map}</div>*/}
            <div>
              <IconButton32
                icon={IconNames8.CARET_RIGHT}
                onClick={next}
                disabled={!canNext}
                title="Next"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
