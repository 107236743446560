import {
  Button,
  Card,
  CardLevel,
  Empty,
  Frow,
  Heading1,
  Heading2,
  IconNames16,
  PaddingLevel,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Report, ReportTemplate } from '../../../javascript/models';
import { useSpraypaintTableNamedParams } from '../shared/hooks/useSpraypaintTable';
import { ReportForm } from './ReportForm';
import { ReportTable } from './tables/ReportTable';
import { ReportTemplateTable } from './tables/ReportTemplateTable';

export default function Index({ canEdit }: { canEdit: boolean }) {
  const [editingReportTemplate, setEditingReportTemplate] = React.useState<ReportTemplate>();
  const { tableProps: reportTemplateTableProps, fetchData: fetchReportTemplates } = useSpraypaintTableNamedParams({
    initialScope: ReportTemplate.per(1000).order({ created_at: 'desc' }),
    searchIdentifier: 'report-templates',
  });

  const { tableProps: reportTableProps, fetchData: fetchReportData, meta } = useSpraypaintTableNamedParams({
    initialScope: Report.per(1000)
      .order({ created_at: 'desc' })
      .stats({
        options: ['report_types'],
        columns: [
          'candidates',
          'companies',
          'internships',
          'applications',
          'contacts',
          'company_submissions',
          'candidate_applications',
        ],
      }),
    searchIdentifier: 'reports',
    initialMeta: { stats: { report_types: [], columns: { candidates: [], companies: [] } } },
  });

  const handleNewReport = () => {
    setEditingReportTemplate(new ReportTemplate({ columns: [], filters: [], reportType: '' }));
  };

  return (
    <>
      <div className="mar-t-1 mar-b-3">
        <Frow alignItems="center" justifyContent="space-between">
          <div>
            <Heading1>Reporting</Heading1>
          </div>
          <div>
            {canEdit && (
              <Button icon={IconNames16.ADD} onClick={handleNewReport}>
                Create Report Template
              </Button>
            )}
          </div>
        </Frow>
      </div>
      {/* Templates */}
      <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.NONE} className="mar-b-3">
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
          <Heading2>Report Templates</Heading2>
        </Card>
        <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
          {reportTemplateTableProps.data.length > 0 ? (
            <ReportTemplateTable
              handleEdit={setEditingReportTemplate}
              tableProps={reportTemplateTableProps}
              fetchData={fetchReportTemplates}
              fetchReportData={fetchReportData}
              canEdit={canEdit}
            />
          ) : (
            <Empty text="No report templates have been created yet" compact={true} />
          )}
        </Card>
      </Card>

      {/* Recent Reports */}
      <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.NONE}>
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
          <Heading2>Reports</Heading2>
        </Card>
        <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
          {reportTableProps.data.length > 0 ? (
            <ReportTable tableProps={reportTableProps} />
          ) : (
            <Empty text="No reports have been run yet" compact={true} />
          )}
        </Card>
      </Card>
      {editingReportTemplate && (
        <ReportForm
          handleClose={() => setEditingReportTemplate(undefined)}
          reportTemplate={editingReportTemplate}
          reportMeta={meta}
          fetchData={fetchReportTemplates}
        />
      )}
    </>
  );
}
