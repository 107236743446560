import { Body, Button, Dialog, FormGroup, Select } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { createToast } from '../../../../../../FlashToaster';

export function CloseJobDialog({ fetchData, job, handleClose, isOpen }) {
  const [selectedCloseOption, setSelectedCloseOption] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSelect = (selectedItem) => {
    setSelectedCloseOption(selectedItem.value);
  };

  const closeOptions = [
    { value: 'Budgetary Constraints', label: 'Budgetary Constraints' },
    { value: 'Candidate Withdrawn', label: 'Candidate Withdrawn' },
    { value: 'Downsizing', label: 'Downsizing' },
    { value: 'Non-Responsive', label: 'Non-Responsive' },
    { value: 'Change in Pledge', label: 'Change in Pledge' },
  ];

  const selectedOption = closeOptions.find((option) => option.value === selectedOption);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const closeKey = 'close-job';
    createToast({ type: 'success', key: closeKey, isLoading: true, text: 'Closing job' });
    job.closeJob = true;
    job.closeReason = selectedCloseOption;
    job.save().then((success) => {
      if (success) {
        createToast({ type: 'success', key: closeKey, text: 'Successfully closed job' });
        fetchData();
        setIsSubmitting(false);
      } else {
        createToast({ type: 'error', key: closeKey, text: 'Unable to close job' });
        setIsSubmitting(false);
      }
    });
  };

  return (
    <Dialog title="Change workstream" isOpen={isOpen} onClose={handleClose}>
      <Body className="mar-b-1">Please select a reason for closing this internship</Body>
      <FormGroup label="Close reason">
        <Select
          onItemSelect={handleSelect}
          items={closeOptions}
          fill={true}
          buttonProps={{ large: true }}
          activeItem={selectedOption}
        />
      </FormGroup>
      <Button
        fill={true}
        text="Update"
        large={true}
        disabled={!selectedCloseOption}
        onClick={handleSubmit}
        loading={isSubmitting}
      />
    </Dialog>
  );
}
