import {
  IconNames16,
  IconNames24,
  SidebarNav,
  SidebarNavHeader,
  SidebarNavPrimaryItem,
  SidebarNavSecondaryItem,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { adminRootPath } from '../../javascript/application/ts_routes';
import useLocalStorage from '../shared/hooks/useLocalStorage';

interface INavigationNestedItem {
  title: string;
  href: string;
  /**
   * Used in UJS
   */
  method?: string;
  /**
   * Should probably be either _blank or null
   */
  target?: string;
}

interface INavigationItem {
  id: string;
  title: string;
  icon: string;
  href: string;
  isOpenByDefault: boolean;
  nestedItems?: INavigationNestedItem[];
}

interface INavigation {
  navigationItems: INavigationItem[];
  defaultCategory: 'mobile' | 'sidebar';
  isOpenByDefault: boolean;
}

const ADMIN_BODY_SIDEBAR_UNPINNED = 'admin-body--sidebar-unpinned';
const ADMIN_BODY_SIDEBAR_PINNED = 'admin-body--sidebar-pinned';

export default function Navigation(props: INavigation) {
  const { navigationItems, isOpenByDefault, defaultCategory } = props;
  const [pinned, setPinned] = useLocalStorage<boolean>('pinned', true);
  const [hovering, setHovering] = React.useState(false);
  const [preventHref, setPreventHref] = React.useState(!pinned);

  const currentPage = window.location.pathname;

  const itemContainsCurrentPage = (item: INavigationItem) => {
    const { href, nestedItems } = item;
    return href === currentPage || (nestedItems && nestedItems.map(({ href: v }) => v).includes(currentPage));
  };

  React.useEffect(() => {
    const body = document.body;

    if (pinned) {
      body.classList.remove(ADMIN_BODY_SIDEBAR_UNPINNED);
      body.classList.add(ADMIN_BODY_SIDEBAR_PINNED);
    } else {
      body.classList.remove(ADMIN_BODY_SIDEBAR_PINNED);
      body.classList.add(ADMIN_BODY_SIDEBAR_UNPINNED);
    }
  }, [pinned]);

  // disable for 500ms after a change to the menu
  React.useEffect(() => {
    setPreventHref(true);
    const timeout = setTimeout(() => {
      setPreventHref(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [hovering, pinned, setPreventHref]);

  const onHover = () => setHovering(true);
  const onHoverEnd = () => setHovering(false);

  const fullscreen = defaultCategory === 'mobile';
  const width = fullscreen ? '100%' : '240px';
  const condensed = !fullscreen && !pinned && !hovering;
  const expandedIcon = pinned ? IconNames16.CHEVRON_LEFT : IconNames16.CHEVRON_RIGHT;

  const onToggle = () => {
    const newPinned = !pinned;
    if (!newPinned) setHovering(false);
    setPinned(newPinned);
  };

  return (
    <SidebarNav
      {...{ condensed }}
      onMouseEnter={onHover}
      onMouseLeave={onHoverEnd}
      style={{
        width: condensed ? null : width,
        zIndex: 1,
        position: 'relative',
      }}
    >
      {!fullscreen && (
        <SidebarNavHeader
          {...{ condensed, onToggle }}
          icon={expandedIcon}
          href={preventHref ? null : adminRootPath()}
        />
      )}
      {navigationItems.map((navigationItem, index) => (
        <NavigationGroup
          key={index}
          {...{ navigationItem, isOpenByDefault, defaultCategory, condensed }}
          active={itemContainsCurrentPage(navigationItem)}
        />
      ))}
    </SidebarNav>
  );
}

const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'building':
      return IconNames24.COMPANY;
    case 'desktop':
      return IconNames24.DASHBOARD;
    case 'check':
      return IconNames24.SURVEYS_REVERSED;
    case 'cog':
      return IconNames24.SETTINGS;
    case 'user-friends':
      return IconNames24.CANDIDATES;
    case 'insights':
      return IconNames24.INSIGHTS;
    case 'recruitment':
      return IconNames24.RECRUITMENT;
    case 'briefcase':
      return IconNames24.JOBS;
    case 'surveys-reversed':
      return IconNames24.SURVEYS_REVERSED;
    case 'form':
      return IconNames24.ONBOARDING;
    case 'candidates':
      return IconNames24.CANDIDATES;
    default:
      return IconNames24.DASHBOARD;
  }
};

interface INavigationGroup {
  navigationItem: INavigationItem;
  defaultCategory: 'mobile' | 'sidebar';
  isOpenByDefault: boolean;
  active: boolean;
  condensed: boolean;
}

function NavigationGroup(props: INavigationGroup) {
  const { navigationItem, defaultCategory, isOpenByDefault, active, condensed } = props;
  const openStateKey = `openedState-${defaultCategory}`;
  const [openedState, setOpenedState] = useLocalStorage<Record<string, 'closed' | 'open'>>(openStateKey, {
    [navigationItem.id]: isOpenByDefault ? 'open' : 'closed',
  });

  const handleSetExpanded = (expandedValue: boolean) => {
    setOpenedState((prevState) => {
      return {
        ...prevState,
        [navigationItem.id]: expandedValue ? 'open' : 'closed',
      };
    });
  };
  const expanded = openedState[navigationItem.id] !== 'closed';
  const hasNestedItems = navigationItem.nestedItems && navigationItem.nestedItems.length > 0;
  const icon = getIcon(navigationItem.icon);

  const currentPage = window.location.pathname;

  const itemIsCurrentPage = (item: INavigationNestedItem) => {
    return item.href === currentPage;
  };

  return (
    <SidebarNavPrimaryItem
      {...{ active, condensed, icon }}
      text={navigationItem.title}
      canExpand={hasNestedItems}
      setExpanded={handleSetExpanded}
      defaultExpanded={expanded}
      href={navigationItem.href}
    >
      {hasNestedItems &&
        navigationItem.nestedItems.map((nestedItem, index) => (
          <SidebarNavSecondaryItem
            key={index}
            {...{ condensed }}
            active={itemIsCurrentPage(nestedItem)}
            data-method={nestedItem.method}
            href={nestedItem.href}
            target={nestedItem.target}
            text={nestedItem.title}
          />
        ))}
    </SidebarNavPrimaryItem>
  );
}
