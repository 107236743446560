import { Col, Frow } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Application } from '../../../javascript/models';
import AllocationRequests from '../dashboard/AllocationRequests';
import { useSpraypaintNamedParams } from '../shared/hooks/useSpraypaint';
import { ReleaseTable } from './recruitmentDashboard/ReleaseTable';

interface IRecruitmentDashboard {
  cycle: { id: string };
  programme: { id: string };
}

export default function RecruitmentDashboard({ cycle, programme }: IRecruitmentDashboard) {
  const { isLoading, meta } = useSpraypaintNamedParams({
    initialScope: Application.per(0)
      .where({ cycle_id: cycle.id, programmeId: programme.id })
      .stats({ grouped_by: ['status'], headline: ['expected_hires_by_today'] })
      .extraParams({ cycle_id: cycle.id, programme_id: programme.id }),
    initialMeta: {
      stats: { grouped_by: { status: { offer: 0, hired: 0 } }, headline: { expected_hires_by_today: 0 } },
    },
  });

  const listItems = [
    { label: 'Offers', value: meta.stats.grouped_by.status.offer || 0 },
    { label: 'Hires', value: meta.stats.grouped_by.status.hired || 0 },
    {
      label: 'Offers + Hires',
      value: (meta.stats.grouped_by.status.offer || 0) + (meta.stats.grouped_by.status.hired || 0),
    },
    { label: 'Projected hires by today', value: meta.stats.headline.expected_hires_by_today },
    {
      label: '% of Projection Hired',
      value: `${(
        ((meta.stats.grouped_by.status.hired || 0) / (meta.stats.headline.expected_hires_by_today || 1)) *
        100
      ).toFixed(2)}%`,
    },
  ];

  return (
    <>
      <Frow flexDirection="column" verticalGutters={40}>
        <Col>
          <AllocationRequests programmeId={programme.id} cycleId={cycle.id} />
        </Col>
        <Col>
          <ReleaseTable programmeId={programme.id} cycleId={cycle.id} />
        </Col>
        {/* <Col>
          {isLoading ? <Spinner /> : <RaisedList listItems={listItems} />}
        </Col>
        <Col>
          <CandidateInsightLineChart
            fetchUrl={expectedApplicantFlowAdminProgrammeInsightsCompanyTimelinesPath(programme.id)}
            subheading="Expected hires"
            insightDescription="Cumulative total of hires based on organisations expected hired end date"
            cycleId={cycle.id}
          />
        </Col>
        <Col>
          <AllocationProgress {...{ cycle, programme }} />
        </Col> */}
      </Frow>
    </>
  );
}
