import {
  Body,
  Card,
  CardLevel,
  Classes,
  Drawer,
  Link,
  PaddingLevel,
  Subheading1,
  Subheading2,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Candidate, JobSeeker } from '../../../../javascript/models';
import { DrawerHeader } from '../../../shared/DrawerHeader';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { useSpraypaintNamedParams } from '../../shared/hooks/useSpraypaint';

interface IShowDrawer {
  candidate: Candidate;
  handleClose: () => void;
  isOpen: boolean;
}

export function ShowDrawer({ candidate, handleClose, isOpen }: IShowDrawer) {
  const { data: jobSeekers, isLoading: isJobSeekersLoading } = useSpraypaintNamedParams({
    initialScope: JobSeeker.where({ candidate_id: candidate.id }).selectExtra(['programme_cycle_name']),
  });

  return (
    <Drawer maxWidth={800} isOpen={isOpen}>
      <DrawerHeader title="Candidate Details" handleClose={handleClose} />
      <div style={{ overflowY: 'scroll' }}>
        <Card
          paddingLevel={PaddingLevel.REGULAR}
          level={CardLevel.NONE}
          cardInnerProps={{ className: 'pad-h-5 pad-v-3' }}
        >
          {isJobSeekersLoading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <div className="mar-b-3">
                <ul className={Classes.LIST_TABULAR}>
                  <li>
                    <Body className="mar-r-2">Red flagged</Body>
                    {candidate.redFlag ? 'Yes' : 'No'}
                  </li>
                  <li>
                    <Body className="mar-r-2">Red flag reason</Body>
                    {candidate.redFlagReason}
                  </li>
                </ul>
              </div>
              <Subheading1 className="mar-b-1">Applications</Subheading1>
              {jobSeekers.map((jobSeeker) => {
                return (
                  <Card key={jobSeeker.id} paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED}>
                    <Subheading2>{jobSeeker.programmeCycleName}</Subheading2>
                    <ul className={Classes.LIST_TABULAR}>
                      <li>
                        <Body className="mar-r-2">First name</Body>
                        {jobSeeker.firstName}
                      </li>
                      <li>
                        <Body className="mar-r-2">Surname</Body>
                        {jobSeeker.lastName}
                      </li>
                      <li>
                        <Body className="mar-r-2">Email</Body>
                        {jobSeeker.email}
                      </li>
                      <li>
                        <Body className="mar-r-2">Phone number</Body>
                        {jobSeeker.phoneNumber}
                      </li>
                      <li>
                        <Body className="mar-r-2">Eligible</Body>
                        {jobSeeker.eligible ? 'Yes' : 'No'}
                      </li>
                      <li>
                        <Body className="mar-r-2">CV Link</Body>
                        <Link href={jobSeeker.cvUrl} target="_blank">
                          Click here
                        </Link>
                      </li>
                      <li>
                        <Body className="mar-r-2">Preference one</Body>
                        {jobSeeker.internshipSectorPreferenceOne}
                      </li>
                      <li>
                        <Body className="mar-r-2">Preference one description</Body>
                        {jobSeeker.internshipSectorPreferenceOneRationale}
                      </li>
                      <li>
                        <Body className="mar-r-2">Preference two</Body>
                        {jobSeeker.internshipSectorPreferenceTwo}
                      </li>
                      <li>
                        <Body className="mar-r-2">Preference two description</Body>
                        {jobSeeker.internshipSectorPreferenceTwoRationale}
                      </li>
                      <li>
                        <Body className="mar-r-2">Preference three</Body>
                        {jobSeeker.internshipSectorPreferenceThree}
                      </li>
                      <li>
                        <Body className="mar-r-2">Preference three description</Body>
                        {jobSeeker.internshipSectorPreferenceThreeRationale}
                      </li>
                      <li>
                        <Body className="mar-r-2">Unconditional offer?</Body>
                        {jobSeeker.unconditionalOffer ? 'Yes' : 'No'}
                      </li>
                      <li>
                        <Body className="mar-r-2">University</Body>
                        {jobSeeker.university}
                      </li>
                      <li>
                        <Body className="mar-r-2">Other University name</Body>
                        {jobSeeker.otherUniversityName}
                      </li>
                      <li>
                        <Body className="mar-r-2">Year of graduation</Body>
                        {jobSeeker.yearOfGraduation}
                      </li>
                      <li>
                        <Body className="mar-r-2">Year of graduation more info</Body>
                        {jobSeeker.yearOfStudyMoreInfo}
                      </li>
                      <li>
                        <Body className="mar-r-2">Level of education</Body>
                        {jobSeeker.levelOfEducation}
                      </li>
                      <li>
                        <Body className="mar-r-2">Degree subject closest match</Body>
                        {jobSeeker.degreeSubject}
                      </li>
                      <li>
                        <Body className="mar-r-2">Ethnicity</Body>
                        {jobSeeker.ethnicity}
                      </li>
                      <li>
                        <Body className="mar-r-2">Other ethnic background</Body>
                        {jobSeeker.otherEthnicBackground}
                      </li>
                      <li>
                        <Body className="mar-r-2">Visa required?</Body>
                        {jobSeeker.visaRequired}
                      </li>
                      <li>
                        <Body className="mar-r-2">Visa required reason</Body>
                        {jobSeeker.visaRequiredReason}
                      </li>
                      <li>
                        <Body className="mar-r-2">Visa not required reason</Body>
                        {jobSeeker.visaNotRequiredReason}
                      </li>
                      <li>
                        <Body className="mar-r-2">Additional visa info</Body>
                        {jobSeeker.moreVisaInfo}
                      </li>
                      <li>
                        <Body className="mar-r-2">Gender</Body>
                        {jobSeeker.gender}
                      </li>
                      <li>
                        <Body className="mar-r-2">Discovery source</Body>
                        {jobSeeker.discoverySource}
                      </li>
                      <li>
                        <Body className="mar-r-2">Discovery source more info</Body>
                        {jobSeeker.discoverySourceMoreInfo}
                      </li>
                      <li>
                        <Body className="mar-r-2">Refugee of asylum status</Body>
                        {jobSeeker.refugeeOrAsylumStatus ? 'Yes' : 'No'}
                      </li>
                      <li>
                        <Body className="mar-r-2">Spent time in care or are carer</Body>
                        {jobSeeker.spentTimeInCareOrCarer ? 'Yes' : 'No'}
                      </li>
                      <li>
                        <Body className="mar-r-2">Secondary school type</Body>
                        {jobSeeker.secondarySchoolType}
                      </li>
                      <li>
                        <Body className="mar-r-2">Highest qualification of parent</Body>
                        {jobSeeker.highestQualificationOfParent}
                      </li>
                      <li>
                        <Body className="mar-r-2">Eligible for free school meals</Body>
                        {jobSeeker.eligibleForSchoolMeals}
                      </li>
                      <li>
                        <Body className="mar-r-2">Household income</Body>
                        {jobSeeker.householdIncome}
                      </li>
                      <li>
                        <Body className="mar-r-2">Postcode at 14</Body>
                        {jobSeeker.postcodeAt14}
                      </li>

                      <li>
                        <Body className="mar-r-2">Parent occupation</Body>
                        {jobSeeker.parentOccupation}
                      </li>
                      <li>
                        <Body className="mar-r-2">Financial support</Body>
                        {jobSeeker.financialSupport ? 'Yes' : 'No'}
                      </li>
                      <li>
                        <Body className="mar-r-2">Disability statements</Body>
                        {jobSeeker.disabilityStatements}
                      </li>
                      <li>
                        <Body className="mar-r-2">Disability conditions</Body>
                        {jobSeeker.disabilityConditions}
                      </li>
                      <li>
                        <Body className="mar-r-2">Social barriers</Body>
                        {jobSeeker.socialBarriers}
                      </li>
                      <li>
                        <Body className="mar-r-2">Additional conditional info</Body>
                        {jobSeeker.furtherConditionInformation}
                      </li>
                      <li>
                        <Body className="mar-r-2">Reasonable adjustments</Body>
                        {jobSeeker.reasonableAdjustments}
                      </li>
                      <li>
                        <Body className="mar-r-2">Reasonable adjustments more information</Body>
                        {jobSeeker.reasonableAdjustmentsInformation}
                      </li>
                    </ul>
                  </Card>
                );
              })}
            </div>
          )}
        </Card>
      </div>
    </Drawer>
  );
}
