import * as $ from 'jquery';
import * as React from 'react';
import {
  Alerter,
  Body, Button,
  Card,
  CardLevel, Classes, Dialog, Frow,
  IconButton40,
  IconNames16, Intent, Menu, MenuDivider, MenuItem,
  PaddingLevel,
  Popover, Select,
  Subheading1,
  WorkflowTag
} from "@pinpointhq/thumbtack";
import PaginationComponent from "../candidates/awaiting/PaginationComponent";
import ReactTable from "react-table";
import {
  adminProgrammeAllocationRequestPath,
  adminProgrammeAllocationRequestsPath, exportAdminProgrammeAllocationRequestsPath
} from "../../../javascript/application/ts_routes";
import {format} from "date-fns";
import {SanitizedRunningText} from "../../shared/SanitizedRunningText";
import {AllocateDialog} from "../shared/AllocateDialog";
import {getCsrfToken} from "../../shared/csrfToken";
import {createToast} from "../../FlashToaster";
import Search from "../../shared/Search";
import { visit } from 'turbolinks';

interface IAllocationRequests {
  cycleId: string;
  programmeId: string;
}

export default function AllocationRequests({ cycleId, programmeId }: IAllocationRequests) {
  const [allocationRequests, setAllocationRequests] = React.useState()
  const [selectedJobId, setSelectedJobId] = React.useState()
  const [selectedReason, setSelectedReason] = React.useState()
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const [status, setStatus] = React.useState<string>('all')

  React.useEffect(
    () => {
      getData();
    }, [searchTerm, status]
  )

  const getData = () => {
    $.ajax({
      url: adminProgrammeAllocationRequestsPath(programmeId),
      type: 'GET',
      dataType: 'JSON',
      data: { search: searchTerm, status, cycle_id: cycleId },
      cache: false,
      success: (data) => setAllocationRequests(data.data),
    });
  }

  const columns = [
    {
      Cell: (row: any) => {
        const status = row.original.attributes.status

        if (status === 'completed') {
          return(
            <WorkflowTag text="Completed" intent={Intent.SUCCESS} />
          )
        }

        if (status === 'rejected') {
          return(
            <WorkflowTag text="Rejected" intent={Intent.DANGER} />
          )
        }

        return(
          <WorkflowTag text="Outstanding" intent={Intent.NONE} />
        )
      },
      Header: 'Status',
      sortable: false,
      width: 140,
    },
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.attributes.company}</div>
        )
      },
      Header: 'Organisation',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.attributes.job_workstream}</div>
        )
      },
      Header: 'Workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <div>
            {row.original.attributes.job_application_count} ({row.original.attributes.additional_allocation_count})
          </div>
        )
      },
      Header: 'Applications',
      sortable: false,
      width: 140,
    },
    {
      Cell: (row: any) => {
        const handleClick = () => setSelectedReason(row.original.attributes.reason)
        return (
          <div onClick={handleClick}>
            <span className={Classes.LINK}>{row.original.attributes.reason}</span>
          </div>
        )
      },
      Header: 'Reason',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <div>
            {format(row.original.attributes.created_at, 'DD/MM/YYYY [at] HH:mm')}
          </div>
        )
      },
      Header: 'Requested at',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        const handleClick = () => setSelectedJobId(row.original.attributes.job_id)
        const handleReject = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, reject',
            intent: Intent.DANGER,
            onConfirm: () => {
              confirmRejection()
            },
            text: 'Are you sure you want to reject this request for additional candidates?',
          });
        }

        const confirmRejection = () => {
            $.ajax({
              cache: false,
              dataType: 'JSON',
              data: {
                authenticity_token: getCsrfToken(),
                allocation_request: { status: 'rejected' }
              },
              success: (data) => {
                createToast({ type: 'success', text: 'Successfully rejected request' });
                getData();
              },
              type: 'PATCH',
              url: adminProgrammeAllocationRequestPath(programmeId, row.original.attributes.id),
            })
        }

        if (row.original.attributes.status !== 'outstanding') return null;

        return(
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem text="Allocate" onClick={handleClick} />
                <MenuDivider />
                <MenuItem text="Reject" onClick={handleReject} intent={Intent.DANGER} />
              </Menu>
            </Popover>
          </div>
        )
      },
      Header: '',
      sortable: false,
      width: 80
    }
  ]

  const identifier = 'allocation-request'

  React.useEffect(() => {
    document.addEventListener(`${identifier}:search`, handleSearch);
    return () => {
      document.removeEventListener(`${identifier}:search`, handleSearch);
    };
  }, [searchTerm]);

  const handleSearch = (event) => setSearchTerm(event.detail.searchTerm);

  const handleClose = () => setSelectedReason(null)
  const handleAllocateClose = () => setSelectedJobId(null)
  const handleSuccess = () => {
    getData();
    setSelectedJobId(null)
  };

  const handleStatusChange = (item) => setStatus(item.value)

  const statuses = [
    { value: 'all', label: 'All Statuses' },
    { value: 'outstanding', label: 'Outstanding' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'completed', label: 'Completed' },
  ]

  const selectStatus = statuses.find((s) => s.value === status)

  const programme = programmeId.toString() === '1' ? '10kbi' : 'able'

  const handleExport = () => {
    visit(exportAdminProgrammeAllocationRequestsPath(programmeId, { search: searchTerm, status, cycle_id: cycleId }))
  }

  return(
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-2">
        <Subheading1>Allocation Requests</Subheading1>
        <Body muted={true} className="mar-t-1">
          Organisations that have requested additional candidates
        </Body>
      </div>
      <div className="mar-b-2">
        <Frow gutters={16} verticalGutters={16}>
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} />
          </div>
          <div>
            <Select
              onItemSelect={handleStatusChange}
              items={statuses}
              activeItem={selectStatus}
              buttonProps={{ large: true }}
            />
          </div>
          <div>
            <Button minimal={true} text="Export" large={true} onClick={handleExport} />
          </div>
        </Frow>
      </div>
      <ReactTable
        columns={columns}
        data={allocationRequests}
        PaginationComponent={PaginationComponent}
        className="-unthemed"
        minRows={1}
        pageSize={10}
      />
      <Dialog title="Reason" isOpen={!!selectedReason} onClose={handleClose}>
        <SanitizedRunningText text={selectedReason} muted={true} />
      </Dialog>
      {selectedJobId && (
        <AllocateDialog
          isOpen={!!selectedJobId}
          jobId={selectedJobId}
          handleClose={handleAllocateClose}
          handleSuccess={handleSuccess}
          programme={programme}
        />
      )}
    </Card>
  )
}