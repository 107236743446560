/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_in(.:format) */
export function newUserSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_in(.:format) */
export function userSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_out(.:format) */
export function destroyUserSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password/new(.:format) */
export function newUserPasswordPath(options?: object): string {
  return "/" + "users" + "/" + "password" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password/edit(.:format) */
export function editUserPasswordPath(options?: object): string {
  return "/" + "users" + "/" + "password" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password(.:format) */
export function userPasswordPath(options?: object): string {
  return "/" + "users" + "/" + "password" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /login(.:format) */
export function loginPath(options?: object): string {
  return "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /webhooks(.:format) */
export function webhooksPath(options?: object): string {
  return "/" + "webhooks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /local_webhooks(.:format) */
export function localWebhooksPath(options?: object): string {
  return "/" + "local_webhooks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /webhooks/pledges(.:format) */
export function webhooksPledgesPath(options?: object): string {
  return "/" + "webhooks" + "/" + "pledges" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /webhooks/applications(.:format) */
export function webhooksApplicationsPath(options?: object): string {
  return "/" + "webhooks" + "/" + "applications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin(.:format) */
export function adminRootPath(options?: object): string {
  return "/" + "admin" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/comments/:id(.:format) */
export function adminCommentPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "comments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/plain_index(.:format) */
export function plainIndexAdminCompaniesPath(options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + "plain_index" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/companies/export(.:format) */
export function exportAdminCompaniesPath(options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/companies/:id/merge(.:format) */
export function mergeAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "merge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:company_id/companies/comments(.:format) */
export function adminCompanyCompaniesCommentsPath(company_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + company_id + "/" + "companies" + "/" + "comments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /admin/companies/:company_id/companies/contacts(.:format) */
export function adminCompanyCompaniesContactsPath(company_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + company_id + "/" + "companies" + "/" + "contacts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /admin/companies/:company_id/companies/contacts/:id(.:format) */
export function adminCompanyCompaniesContactPath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + company_id + "/" + "companies" + "/" + "contacts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /admin/companies(.:format) */
export function adminCompaniesPath(options?: object): string {
  return "/" + "admin" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/companies/new(.:format) */
export function newAdminCompanyPath(options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/companies/:id/edit(.:format) */
export function editAdminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies/:id(.:format) */
export function adminCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/companies_cycles/export(.:format) */
export function exportAdminCompaniesCyclesPath(options?: object): string {
  return "/" + "admin" + "/" + "companies_cycles" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/companies_cycles(.:format) */
export function adminCompaniesCyclesPath(options?: object): string {
  return "/" + "admin" + "/" + "companies_cycles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/jobs/:id/allocate(.:format) */
export function allocateAdminJobPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "jobs" + "/" + id + "/" + "allocate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/jobs/export(.:format) */
export function exportAdminJobsPath(options?: object): string {
  return "/" + "admin" + "/" + "jobs" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/jobs(.:format) */
export function adminJobsPath(options?: object): string {
  return "/" + "admin" + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/jobs/:id(.:format) */
export function adminJobPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "jobs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/api/applications(.:format) */
export function adminApiApplicationsPath(options?: object): string {
  return "/" + "admin" + "/" + "api" + "/" + "applications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/api/companies(.:format) */
export function adminApiCompaniesPath(options?: object): string {
  return "/" + "admin" + "/" + "api" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/api/companies/:id(.:format) */
export function adminApiCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "api" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/api/job_seekers(.:format) */
export function adminApiJobSeekersPath(options?: object): string {
  return "/" + "admin" + "/" + "api" + "/" + "job_seekers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/api/job_seekers/:id(.:format) */
export function adminApiJobSeekerPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "api" + "/" + "job_seekers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/api/jobs(.:format) */
export function adminApiJobsPath(options?: object): string {
  return "/" + "admin" + "/" + "api" + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/api/jobs/:id(.:format) */
export function adminApiJobPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "api" + "/" + "jobs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/applications(.:format) */
export function adminGraphitiApplicationsPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "applications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/applications/new(.:format) */
export function newAdminGraphitiApplicationPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "applications" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/applications/:id/edit(.:format) */
export function editAdminGraphitiApplicationPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "applications" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/applications/:id(.:format) */
export function adminGraphitiApplicationPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "applications" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/companies(.:format) */
export function adminGraphitiCompaniesPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/companies/new(.:format) */
export function newAdminGraphitiCompanyPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/companies/:id/edit(.:format) */
export function editAdminGraphitiCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/companies/:id(.:format) */
export function adminGraphitiCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/companies_cycles(.:format) */
export function adminGraphitiCompaniesCyclesPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies_cycles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/companies_cycles/new(.:format) */
export function newAdminGraphitiCompaniesCyclePath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies_cycles" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/companies_cycles/:id/edit(.:format) */
export function editAdminGraphitiCompaniesCyclePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies_cycles" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/companies_cycles/:id(.:format) */
export function adminGraphitiCompaniesCyclePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "companies_cycles" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/company_cycle_informations(.:format) */
export function adminGraphitiCompanyCycleInformationsPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "company_cycle_informations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/company_cycle_informations/new(.:format) */
export function newAdminGraphitiCompanyCycleInformationPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "company_cycle_informations" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/company_cycle_informations/:id/edit(.:format) */
export function editAdminGraphitiCompanyCycleInformationPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "company_cycle_informations" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/company_cycle_informations/:id(.:format) */
export function adminGraphitiCompanyCycleInformationPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "company_cycle_informations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/contacts(.:format) */
export function adminGraphitiContactsPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "contacts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/contacts/new(.:format) */
export function newAdminGraphitiContactPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "contacts" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/contacts/:id/edit(.:format) */
export function editAdminGraphitiContactPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "contacts" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/contacts/:id(.:format) */
export function adminGraphitiContactPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "contacts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/internships(.:format) */
export function adminGraphitiInternshipsPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "internships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/internships/new(.:format) */
export function newAdminGraphitiInternshipPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "internships" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/internships/:id/edit(.:format) */
export function editAdminGraphitiInternshipPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "internships" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/internships/:id(.:format) */
export function adminGraphitiInternshipPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "internships" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/jobs(.:format) */
export function adminGraphitiJobsPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/jobs/new(.:format) */
export function newAdminGraphitiJobPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "jobs" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/jobs/:id/edit(.:format) */
export function editAdminGraphitiJobPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "jobs" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/jobs/:id(.:format) */
export function adminGraphitiJobPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "jobs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/job_seekers(.:format) */
export function adminGraphitiJobSeekersPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "job_seekers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/job_seekers/new(.:format) */
export function newAdminGraphitiJobSeekerPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "job_seekers" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/job_seekers/:id/edit(.:format) */
export function editAdminGraphitiJobSeekerPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "job_seekers" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/job_seekers/:id(.:format) */
export function adminGraphitiJobSeekerPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "job_seekers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/reports(.:format) */
export function adminGraphitiReportsPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "reports" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/reports/new(.:format) */
export function newAdminGraphitiReportPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "reports" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/reports/:id/edit(.:format) */
export function editAdminGraphitiReportPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "reports" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/reports/:id(.:format) */
export function adminGraphitiReportPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "reports" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/report_templates(.:format) */
export function adminGraphitiReportTemplatesPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "report_templates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/report_templates/new(.:format) */
export function newAdminGraphitiReportTemplatePath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "report_templates" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/report_templates/:id/edit(.:format) */
export function editAdminGraphitiReportTemplatePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "report_templates" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/report_templates/:id(.:format) */
export function adminGraphitiReportTemplatePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "report_templates" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/universities(.:format) */
export function adminGraphitiUniversitiesPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "universities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/universities/new(.:format) */
export function newAdminGraphitiUniversityPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "universities" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/universities/:id/edit(.:format) */
export function editAdminGraphitiUniversityPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "universities" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/universities/:id(.:format) */
export function adminGraphitiUniversityPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "universities" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/workstreams(.:format) */
export function adminGraphitiWorkstreamsPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "workstreams" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/workstreams/new(.:format) */
export function newAdminGraphitiWorkstreamPath(options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "workstreams" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/graphiti/workstreams/:id/edit(.:format) */
export function editAdminGraphitiWorkstreamPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "workstreams" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/graphiti/workstreams/:id(.:format) */
export function adminGraphitiWorkstreamPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "graphiti" + "/" + "workstreams" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/insights/jobs/workstream_stats(.:format) */
export function workstreamStatsAdminInsightsJobsPath(options?: object): string {
  return "/" + "admin" + "/" + "insights" + "/" + "jobs" + "/" + "workstream_stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/reports(.:format) */
export function adminReportsPath(options?: object): string {
  return "/" + "admin" + "/" + "reports" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/reports/:id(.:format) */
export function adminReportPath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "reports" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/programmes/:programme_id/allocation_requests/export(.:format) */
export function exportAdminProgrammeAllocationRequestsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "allocation_requests" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/allocation_requests(.:format) */
export function adminProgrammeAllocationRequestsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "allocation_requests" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/allocation_requests/new(.:format) */
export function newAdminProgrammeAllocationRequestPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "allocation_requests" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/allocation_requests/:id/edit(.:format) */
export function editAdminProgrammeAllocationRequestPath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "allocation_requests" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /admin/programmes/:programme_id/allocation_requests/:id(.:format) */
export function adminProgrammeAllocationRequestPath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "allocation_requests" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /admin/programmes/:programme_id/release_requests/export(.:format) */
export function exportAdminProgrammeReleaseRequestsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "release_requests" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/awaiting(.:format) */
export function awaitingAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "awaiting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/hired(.:format) */
export function hiredAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "hired" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/rejected(.:format) */
export function rejectedAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "rejected" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/offer(.:format) */
export function offerAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "offer" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/interview(.:format) */
export function interviewAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "interview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/review(.:format) */
export function reviewAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "review" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/export(.:format) */
export function exportAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/successful_export(.:format) */
export function successfulExportAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "successful_export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/application_email_redirect(.:format) */
export function applicationEmailRedirectAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "application_email_redirect" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/successful(.:format) */
export function successfulAdminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + "successful" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/:id/assign(.:format) */
export function assignAdminProgrammeCandidatePath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + id + "/" + "assign" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /admin/programmes/:programme_id/candidates(.:format) */
export function adminProgrammeCandidatesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidates/:id(.:format) */
export function adminProgrammeCandidatePath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidates" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /admin/programmes/:programme_id/companies(.:format) */
export function adminProgrammeCompaniesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/companies/new(.:format) */
export function newAdminProgrammeCompanyPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "companies" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/companies/:id/edit(.:format) */
export function editAdminProgrammeCompanyPath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "companies" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /admin/programmes/:programme_id/companies/:id(.:format) */
export function adminProgrammeCompanyPath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /admin/programmes/:programme_id/companies_cycles/:id(.:format) */
export function adminProgrammeCompaniesCyclePath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "companies_cycles" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /admin/programmes/:programme_id/company_submissions/export(.:format) */
export function exportAdminProgrammeCompanySubmissionsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "company_submissions" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/company_submissions(.:format) */
export function adminProgrammeCompanySubmissionsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "company_submissions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/company_timelines/export(.:format) */
export function exportAdminProgrammeCompanyTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "company_timelines" + "/" + "export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/company_timelines(.:format) */
export function adminProgrammeCompanyTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "company_timelines" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_one_progress(.:format) */
export function allocationOneProgressAdminProgrammeInsightsCompanyCyclesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_one_progress" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_two_progress(.:format) */
export function allocationTwoProgressAdminProgrammeInsightsCompanyCyclesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_two_progress" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/interviewing_companies(.:format) */
export function interviewingCompaniesAdminProgrammeInsightsCompanyCyclesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "interviewing_companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/offered_companies(.:format) */
export function offeredCompaniesAdminProgrammeInsightsCompanyCyclesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "offered_companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/hired_companies(.:format) */
export function hiredCompaniesAdminProgrammeInsightsCompanyCyclesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "hired_companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_ones/timelines_incomplete(.:format) */
export function timelinesIncompleteAdminProgrammeInsightsCompanyCyclesAllocationOnesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_ones" + "/" + "timelines_incomplete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_ones/timelines_complete(.:format) */
export function timelinesCompleteAdminProgrammeInsightsCompanyCyclesAllocationOnesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_ones" + "/" + "timelines_complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_ones/not_started(.:format) */
export function notStartedAdminProgrammeInsightsCompanyCyclesAllocationOnesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_ones" + "/" + "not_started" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_ones/active(.:format) */
export function activeAdminProgrammeInsightsCompanyCyclesAllocationOnesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_ones" + "/" + "active" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_ones/offering(.:format) */
export function offeringAdminProgrammeInsightsCompanyCyclesAllocationOnesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_ones" + "/" + "offering" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_ones/offering_hiring(.:format) */
export function offeringHiringAdminProgrammeInsightsCompanyCyclesAllocationOnesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_ones" + "/" + "offering_hiring" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_ones/completed(.:format) */
export function completedAdminProgrammeInsightsCompanyCyclesAllocationOnesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_ones" + "/" + "completed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_twos/timelines_incomplete(.:format) */
export function timelinesIncompleteAdminProgrammeInsightsCompanyCyclesAllocationTwosPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_twos" + "/" + "timelines_incomplete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_twos/timelines_complete(.:format) */
export function timelinesCompleteAdminProgrammeInsightsCompanyCyclesAllocationTwosPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_twos" + "/" + "timelines_complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_twos/not_started(.:format) */
export function notStartedAdminProgrammeInsightsCompanyCyclesAllocationTwosPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_twos" + "/" + "not_started" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_twos/active(.:format) */
export function activeAdminProgrammeInsightsCompanyCyclesAllocationTwosPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_twos" + "/" + "active" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_twos/offering(.:format) */
export function offeringAdminProgrammeInsightsCompanyCyclesAllocationTwosPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_twos" + "/" + "offering" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_twos/offering_hiring(.:format) */
export function offeringHiringAdminProgrammeInsightsCompanyCyclesAllocationTwosPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_twos" + "/" + "offering_hiring" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_cycles/allocation_twos/completed(.:format) */
export function completedAdminProgrammeInsightsCompanyCyclesAllocationTwosPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_cycles" + "/" + "allocation_twos" + "/" + "completed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_timelines/completed_timelines/completed(.:format) */
export function completedAdminProgrammeInsightsCompanyTimelinesCompletedTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_timelines" + "/" + "completed_timelines" + "/" + "completed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_timelines/completed_timelines/outstanding(.:format) */
export function outstandingAdminProgrammeInsightsCompanyTimelinesCompletedTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_timelines" + "/" + "completed_timelines" + "/" + "outstanding" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_submissions/invited_to_pinpoint(.:format) */
export function invitedToPinpointAdminProgrammeInsightsCompanySubmissionsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_submissions" + "/" + "invited_to_pinpoint" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_timelines/completed_timelines(.:format) */
export function completedTimelinesAdminProgrammeInsightsCompanyTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_timelines" + "/" + "completed_timelines" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_timelines/timeline_match(.:format) */
export function timelineMatchAdminProgrammeInsightsCompanyTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_timelines" + "/" + "timeline_match" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/company_timelines/expected_applicant_flow(.:format) */
export function expectedApplicantFlowAdminProgrammeInsightsCompanyTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "company_timelines" + "/" + "expected_applicant_flow" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/workstreams_by_preference_one(.:format) */
export function workstreamsByPreferenceOneAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "workstreams_by_preference_one" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/workstreams_by_preference_two(.:format) */
export function workstreamsByPreferenceTwoAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "workstreams_by_preference_two" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/workstreams_by_preference_three(.:format) */
export function workstreamsByPreferenceThreeAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "workstreams_by_preference_three" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/workstreams_by_preference_four(.:format) */
export function workstreamsByPreferenceFourAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "workstreams_by_preference_four" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/discovery_source_by_candidates(.:format) */
export function discoverySourceByCandidatesAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "discovery_source_by_candidates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/level_of_education_by_candidates(.:format) */
export function levelOfEducationByCandidatesAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "level_of_education_by_candidates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/sponsorship_required_by_candidates(.:format) */
export function sponsorshipRequiredByCandidatesAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "sponsorship_required_by_candidates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/year_of_graduation_by_candidates(.:format) */
export function yearOfGraduationByCandidatesAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "year_of_graduation_by_candidates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/university_tier_by_candidates(.:format) */
export function universityTierByCandidatesAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "university_tier_by_candidates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/candidate_quality(.:format) */
export function candidateQualityAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "candidate_quality" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/disabled(.:format) */
export function disabledAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "disabled" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/disability(.:format) */
export function disabilityAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "disability" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/gender(.:format) */
export function genderAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "gender" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/ethnicity(.:format) */
export function ethnicityAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "ethnicity" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/refugee_status(.:format) */
export function refugeeStatusAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "refugee_status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/spent_time_in_care(.:format) */
export function spentTimeInCareAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "spent_time_in_care" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/secondary_school(.:format) */
export function secondarySchoolAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "secondary_school" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/parent_qualification(.:format) */
export function parentQualificationAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "parent_qualification" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/free_school_meals(.:format) */
export function freeSchoolMealsAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "free_school_meals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/parent_complete_degree(.:format) */
export function parentCompleteDegreeAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "parent_complete_degree" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/job_seekers/household_income(.:format) */
export function householdIncomeAdminProgrammeInsightsJobSeekersPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "job_seekers" + "/" + "household_income" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/internships/internships_by_region(.:format) */
export function internshipsByRegionAdminProgrammeInsightsInternshipsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "internships" + "/" + "internships_by_region" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/internships/internships_by_location(.:format) */
export function internshipsByLocationAdminProgrammeInsightsInternshipsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "internships" + "/" + "internships_by_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/insights/internships/internships_by_workstream(.:format) */
export function internshipsByWorkstreamAdminProgrammeInsightsInternshipsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "insights" + "/" + "internships" + "/" + "internships_by_workstream" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/internships(.:format) */
export function adminProgrammeInternshipsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "internships" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/book_building(.:format) */
export function adminProgrammeBookBuildingPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "book_building" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/candidate_dashboard(.:format) */
export function adminProgrammeCandidateDashboardPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "candidate_dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/recruitment_dashboard(.:format) */
export function adminProgrammeRecruitmentDashboardPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "recruitment_dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/settings/integrations/ping_pinpoint(.:format) */
export function pingPinpointAdminProgrammeSettingsIntegrationsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "settings" + "/" + "integrations" + "/" + "ping_pinpoint" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes/:programme_id/settings/integrations(.:format) */
export function adminProgrammeSettingsIntegrationsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + programme_id + "/" + "settings" + "/" + "integrations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /admin/programmes(.:format) */
export function adminProgrammesPath(options?: object): string {
  return "/" + "admin" + "/" + "programmes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/programmes/:id(.:format) */
export function adminProgrammePath(id: ScalarType, options?: object): string {
  return "/" + "admin" + "/" + "programmes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /admin/logout(.:format) */
export function adminLogoutPath(options?: object): string {
  return "/" + "admin" + "/" + "logout" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/candidates(.:format) */
export function apiV1CandidatesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "candidates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/candidates/new(.:format) */
export function newApiV1CandidatePath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "candidates" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/candidates/:id/edit(.:format) */
export function editApiV1CandidatePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "candidates" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/v1/candidates/:id(.:format) */
export function apiV1CandidatePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "candidates" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/v1/jobs(.:format) */
export function apiV1JobsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/company_timelines(.:format) */
export function apiV1CompanyTimelinesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "company_timelines" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /programmes/:programme_id/additional_submissions/thanks(.:format) */
export function thanksProgrammeAdditionalSubmissionsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "additional_submissions" + "/" + "thanks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /programmes/:programme_id/additional_submissions/:id/edit(.:format) */
export function editProgrammeAdditionalSubmissionPath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "additional_submissions" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /programmes/:programme_id/additional_submissions/:id(.:format) */
export function programmeAdditionalSubmissionPath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "additional_submissions" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /programmes/:programme_id/company_submissions/thanks(.:format) */
export function thanksProgrammeCompanySubmissionsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_submissions" + "/" + "thanks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /programmes/:programme_id/company_submissions(.:format) */
export function programmeCompanySubmissionsPath(programme_id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_submissions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /programmes/:programme_id/company_submissions/new(.:format) */
export function newProgrammeCompanySubmissionPath(programme_id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_submissions" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /programmes/:programme_id/company_timelines/thanks(.:format) */
export function thanksProgrammeCompanyTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_timelines" + "/" + "thanks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /programmes/:programme_id/company_timelines(.:format) */
export function programmeCompanyTimelinesPath(programme_id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_timelines" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /programmes/:programme_id/company_timelines/new(.:format) */
export function newProgrammeCompanyTimelinePath(programme_id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_timelines" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","format"]);
}

/** /programmes/:programme_id/company_timelines/:id/edit(.:format) */
export function editProgrammeCompanyTimelinePath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_timelines" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /programmes/:programme_id/company_timelines/:id(.:format) */
export function programmeCompanyTimelinePath(programme_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "programmes" + "/" + programme_id + "/" + "company_timelines" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["programme_id","id","format"]);
}

/** /company_submissions(.:format) */
export function companySubmissionsPath(options?: object): string {
  return "/" + "company_submissions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /company_submissions/new(.:format) */
export function newCompanySubmissionPath(options?: object): string {
  return "/" + "company_submissions" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /thanks(.:format) */
export function thanksPath(options?: object): string {
  return "/" + "thanks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
