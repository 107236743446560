import * as React from 'react';
import {
  Body,
  Card,
  CardLevel,
  Classes,
  Colors, Divider,
  Empty,
  Heading2,
  Lead, Link,
  PaddingLevel,
  Subheading2
} from "@pinpointhq/thumbtack";
import {CompaniesCycle} from "../../../../../../javascript/models";
import { newProgrammeCompanySubmissionPath } from '../../../../../../javascript/application/ts_routes'
import {format} from "date-fns";
import {CenteredContent} from "../../../../../shared/CenteredContent";

export function Submissions({ activeCycle }: { activeCycle: CompaniesCycle }) {
  if (!activeCycle) {
    return(
      <CenteredContent maxWidth={1200}>
        <Heading2 className="mar-b-3">Submission</Heading2>
        <Empty text="There was no submission information from this company for the selected cycle" />
      </CenteredContent>
    )
  }
  const { companySubmission } = activeCycle

  return(
    <CenteredContent maxWidth={1200}>
      <Heading2 className="mar-b-3">Submission</Heading2>
      {companySubmission ? (
        <div>
          <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED} style={{ background: Colors.IVORY_10 }}>
            <div>
              <Subheading2 className="mar-b-1">Submission</Subheading2>
              <Lead>{activeCycle.cycleName}</Lead>
            </div>
          </Card>
          <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
            <ul className={Classes.LIST_TABULAR}>
              <li>
                <Body semiBold={true}>Submission form</Body>
                <Body>
                  <Link href={newProgrammeCompanySubmissionPath(activeCycle.programmeId, { token: companySubmission.token })} target="_blank">View form</Link>
                </Body>
              </li>
              <li>
                <Body semiBold={true}>Submission date</Body>
                <Body>{format(companySubmission.createdAt, 'MMMM Do YYYY')}</Body>
              </li>
              <li>
                <Body semiBold={true}>Last updated at</Body>
                <Body>{format(companySubmission.updatedAt, 'MMMM Do YYYY')}</Body>
              </li>
              <li>
                <Body semiBold={true}>Company name</Body>
                <Body>{companySubmission.companyName}</Body>
              </li>
              <li>
                <Body semiBold={true}>Company workstream</Body>
                <Body>{companySubmission.workstream}</Body>
              </li>
              <li>
                <Body semiBold={true}>Sponsorship</Body>
                <Body>{companySubmission.sponsorship ? 'Yes' : 'No'}</Body>
              </li>
              <li>
                <Body semiBold={true}>Contact name</Body>
                <Body>{companySubmission.contactName}</Body>
              </li>
              <li>
                <Body semiBold={true}>Contact email</Body>
                <Body>{companySubmission.contactEmail}</Body>
              </li>
              <li>
                <Body semiBold={true}>Contact number</Body>
                <Body>{companySubmission.contactMobileNumber}</Body>
              </li>
              <li>
                <Body semiBold={true}>Secondary Contact name</Body>
                <Body>{companySubmission.secondaryContactName}</Body>
              </li>
              <li>
                <Body semiBold={true}>Secondary Contact email</Body>
                <Body>{companySubmission.secondaryContactEmail}</Body>
              </li>
              <li>
                <Body semiBold={true}>Secondary Contact number</Body>
                <Body>{companySubmission.secondaryContactMobileNumber}</Body>
              </li>
              <li>
                <Body semiBold={true}>Internships</Body>
                <Body className="mar-l-2 text-left col-flex-grow-1">{companySubmission.internships.map((internship, index) => {
                  return(
                    <React.Fragment key={`${internship.number}-${index}`}>
                      Title: {internship.title} <br/>
                      Region: {internship.region} <br/>
                      Workstream: {internship.workstream} <br/>
                      Number: {internship.number}
                      {(index + 1 !== companySubmission.internships.length) && <Divider className="mar-v-3" />}
                    </React.Fragment>
                  )
                })}</Body>
              </li>
            </ul>
          </Card>
        </div>
      ) : (<Empty text="Company did not make a submission for the selected cycle" />)}
    </CenteredContent>
  )
}
