import {
  Caption,
  IconButton32,
  IconNames8,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Subheading2,
} from '@pinpointhq/thumbtack';
import * as React from 'react';

export function NewPaginationComponent(tableProps) {
  const { data, itemsPerPageText, meta, page, pageSize, handlePageChange, setPageSize, showingText, xOfXText, canPrevious, canNext } = tableProps

  function onPageSizeChange(newPageSize: number) {
    handlePageChange(0);
    setPageSize(newPageSize);
  }

  function onPageChange(newPage: number) {
    handlePageChange(newPage);
  }

  if (data.length === 0) return null;

  const menuItems = [10, 20, 50, 100].map((size) => {
    return <MenuItem key={size} onClick={() => onPageSizeChange(size)} text={size} />;
  });

  // PageNumber is 0 indexed in ReactTable but starts for 1 for Graphiti
  const startNumber = pageSize * page + 1;
  const endNumber = Math.min(startNumber - 1 + pageSize, meta.total);

  function back() {
    onPageChange(page - 1);
  }

  function next() {
    onPageChange(page + 1);
  }

  return (
    <div className="pad-v-2 pad-h-3">
      <div className="frow frow--items-center frow--justify-between frow--gutters-2x">
        <div className="frow frow--items-center frow--gutters-2x">
          <div>
            <div className="frow frow--items-center frow--gutters">
              <Caption>{itemsPerPageText || 'Items per page'}</Caption>
              <Popover>
                <MenuTrigger>
                  <Subheading2 muted={true}>{pageSize}</Subheading2>
                </MenuTrigger>
                <Menu>{menuItems}</Menu>
              </Popover>
            </div>
          </div>
          <Caption muted={true}>
            {showingText || 'Showing'} {startNumber} - {endNumber} {xOfXText || 'of'} {meta.total}
          </Caption>
        </div>
        <div>
          <div className="frow frow--gutters frow--nowrap">
            <div>
              <IconButton32 icon={IconNames8.CARET_LEFT} onClick={back} disabled={!canPrevious} title="Previous" />
            </div>
            <div>
              <IconButton32 icon={IconNames8.CARET_RIGHT} onClick={next} disabled={!canNext} title="Next" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPaginationComponent;
