import { IconButton40, IconNames16, Intent, Menu, MenuItem, Popover } from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { ReportTemplate } from '../../../../javascript/models';
import humanize from '../../../shared/utilities/humanize';
import NewPaginationComponent from '../../shared/NewPaginationComponent';

export function ReportTemplateTable({ canEdit, tableProps, fetchData, handleEdit, fetchReportData }) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Cell: (row: { original: ReportTemplate }) => {
        return humanize(row.original.reportType);
      },
      Header: 'Report type',
      accessor: 'reportType',
    },
    {
      Cell: (row: { original: ReportTemplate }) => {
        const { original: reportTemplate } = row;
        const handleDelete = () => {
          reportTemplate.destroy().then((success) => {
            if (success) {
              fetchData();
            }
          });
        };

        const handleRunReport = () => {
          reportTemplate.runReport = true;
          reportTemplate.updatedAt = new Date().toString();
          reportTemplate.save().then((success) => {
            if (success) {
              fetchReportData();
            }
          });
        };

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} data-testid="report-template-overflow-menu" />
              <Menu>
                <MenuItem text="Run report" onClick={handleRunReport} />
                {canEdit && <MenuItem text="Edit" onClick={() => handleEdit(row.original)} />}
                {canEdit && <MenuItem text="Delete" onClick={handleDelete} intent={Intent.DANGER} />}
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      accessor: 'id',
      sortable: false,
      width: 80,
    },
  ];

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  return (
    <ReactTable
      {...tableProps}
      columns={columns}
      PaginationComponent={NewPaginationComponent}
      getPaginationProps={paginationProps}
      className="-unthemed"
      minRows={1}
    />
  );
}
