import * as React from 'react';
import { Card, CardLevel, Classes, Colors, PaddingLevel, Tab, Tabs } from '@pinpointhq/thumbtack';

export default function CandidateSurveySettings() {
  return(
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.NONE}>
      <Tabs id="survey-tabs" selectedTabId="candidate-survey">
        <Tab id="candidate-survey">Candidate Surveys</Tab>
        <Tab id="firm-survey">Firm Surveys</Tab>
        <div className={Classes.FLEX_EXPANDER} style={{ backgroundColor: Colors.SAGE_50 }} />
      </Tabs>
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.EXPANDED}>

      </Card>
    </Card>
  )
}
