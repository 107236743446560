import {
  Card,
  CardLevel,
  Frow,
  Heading1,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuItem,
  PaddingLevel,
  Popover,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { User } from '../../javascript/models';
import { createToast } from '../FlashToaster';
import { useSpraypaintTableNamedParams } from './shared/hooks/useSpraypaintTable';
import NewPaginationComponent from './shared/NewPaginationComponent';

export default function Users({ isSuperAdmin, userId }: { isSuperAdmin: boolean; userId: string }) {
  const handleNewUser = () => {
    console.log('Do it');
  };

  const { tableProps, fetchData } = useSpraypaintTableNamedParams({
    initialScope: User.per(1000).where({ discarded: false }),
    searchIdentifier: 'users',
  });

  const columns = [
    {
      Header: 'Name',
      accessor: 'fullName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Cell: (row: { original: User }) => {
        return row.original.superAdmin ? 'Yes' : 'No';
      },
      Header: 'Super Admin',
      accessor: 'superAdmin',
    },
    {
      Cell: (row: any) => {
        if (!isSuperAdmin || userId === row.original.id) return null;

        const handleDelete = () => {
          row.original.destroy().then((success: boolean) => {
            if (success) {
              createToast({ type: 'success', text: 'User deleted' });
              fetchData();
            }
          });
        };

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem intent={Intent.DANGER} text="Delete" onClick={handleDelete} />
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      sortable: false,
      width: 80,
    },
  ];

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  return (
    <>
      <div className="mar-b-3">
        <Frow alignItems="center" justifyContent="space-between">
          <div>
            <Heading1>Users</Heading1>
          </div>
          {/* <div>
            {isSuperAdmin && (
              <Button icon={IconNames16.ADD} onClick={handleNewUser}>
                Add User
              </Button>
            )}
          </div> */}
        </Frow>
      </div>
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </>
  );
}
