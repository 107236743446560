import * as React from 'react';
import * as $ from 'jquery';
import {Alerter, Button, Dialog, FormGroup, Input, Intent, MultiSelect, Select, Switch} from "@pinpointhq/thumbtack";
import {allocateAdminJobPath} from "../../../javascript/application/ts_routes";
import {getCsrfToken} from "../../shared/csrfToken";
import {createToast} from "../../FlashToaster";
import {ajaxErrorString} from "../../shared/ajaxErrorString";

const sectorOptions = [
  'Accountancy & Finance',
  'Architecture',
  'Automotive',
  'Built Environment',
  'Chemical Engineering',
  'Civil Engineering',
  'Compliance & Legal Support',
  'Construction',
  'Consumer & Retail',
  'Corporate & Investment Banking',
  'Creative',
  'Culture',
  'Data',
  'Education (Academia)',
  'Education (Non-Academic)',
  'Financial Services Data & Technology',
  'Health Data Research',
  'Healthcare Management',
  'Human Resources',
  'Insurance & Long-Term Savings',
  'Investment Management',
  'Law (In house)',
  'Law (Private Practice)',
  'Management & Strategy Consulting',
  'Market Research',
  'Markets (Sales & Trading)',
  'Marketing & Advertising',
  'Mechanical Engineering',
  'Media',
  'Not-For-Profit',
  'Operations',
  'Pharmaceutical & Life Sciences',
  'Policy & Public Sector',
  'Public Relations',
  'Real Estate & Property',
  'Recruitment & Executive Search',
  'Retail Banking',
  'Sales',
  'Technology (non technical)',
  'Technology (technical)',
  'The Bar',
  'Utilities',
]

const educationOptions = [
  'Undergraduate (Foundation Year)',
  'Undergraduate (First Year)',
  'Undergraduate (Penultimate Year)',
  'Undergraduate (Final Year)',
  'Undergraduate (Other Year)',
  'Recent Graduate',
  'Postgraduate',
  'PhD',
  'Gap year'
]

const graduationOptions = [
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028'
]

const degree_subjects = [
  "Accounting",
  "Aerospace Engineering",
  "Agriculture",
  "American Studies",
  "Anatomy",
  "Animal Science",
  "Anthropology",
  "Archaeology",
  "Architecture",
  "Art History",
  "Biology",
  "Building and Surveying",
  "Business and Management Studies",
  "Chemical Engineering",
  "Chemistry",
  "Civil Engineering",
  "Classics",
  "Computer Games Design and Programming",
  "Computer Science",
  "Creative Writing",
  "Dentistry",
  "Design",
  "Drama and Theatre Studies",
  "Economics",
  "Electronic and Electrical Engineering",
  "English Language and Literature",
  "Environmental Science",
  "Finance",
  "Fine Art",
  "Food and Beverage Studies",
  "Forensic Science",
  "French",
  "General Engineering",
  "Genetics",
  "Geography",
  "Geology",
  "German",
  "History",
  "Information Management and Museum Studies",
  "Information Systems",
  "Japanese",
  "Journalism",
  "Law",
  "Linguistics",
  "Marine and Ocean Sciences",
  "Marketing",
  "Materials Science",
  "Maths",
  "Mechanical Engineering",
  "Media Studies",
  "Medicine",
  "Microbiology",
  "Midwifery",
  "Music",
  "Nursing",
  "Nutrition",
  "Operational Research",
  "Optometry",
  "Other",
  "Pharmacy",
  "Philosophy",
  "Photography and Film",
  "Physics",
  "Physiology",
  "Physiotherapy",
  "Planning",
  "Policy",
  "Politics",
  "Psychology",
  "Public Relations",
  "Publishing",
  "Radiography and Medical Technology",
  "Social Work",
  "Sociology",
  "Software Engineering",
  "Spanish",
  "Speech Therapy and Audiology",
  "Sports Science",
  "Statistics",
  "Teacher Training",
  "Theology and Religious Studies",
  "Tourism and Travel",
  "Veterinary Medicine",
  "Zoology",
]

export function AllocateDialog({ jobId, isOpen, handleClose, handleSuccess, initialSector = null, programme = '10kbi' }) {
  const [allocationAmount, setAllocationAmount] = React.useState<number>(10)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const handleNumberChange = (event) => {
    setAllocationAmount(event.target.value)
  }

  const handleClick = () => {
    if (!allocationAmount || allocationAmount < 1) {
      createToast({ type: 'error', text: 'You must choose 1 or more applications to create' })
      return;
    }

    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, allocate',
      intent: Intent.NONE,
      onConfirm: () => {
        confirmAllocate()
      },
      text: 'Are you sure you want to allocate additional candidates to this role?',
    });
  }

  const key = 'allocating'

  const [selectedSectors, setSelectedSectors] = React.useState([initialSector])
  const [selectedGender, setSelectedGender] = React.useState(null)
  const [selectedEducation, setSelectedEducation] = React.useState([])
  const [selectedGraduation, setSelectedGraduation] = React.useState([])
  const [selectedDegree, setSelectedDegree] = React.useState([])
  const [useLocation, setUseLocation] = React.useState<boolean>(false)

  const confirmAllocate = () => {
    setIsSubmitting(true)
    createToast({ isLoading: true, type: 'success', text: 'Allocating additional candidates', key })
    $.ajax({
      url: allocateAdminJobPath(jobId),
      data: {
        authenticity_token: getCsrfToken(),
        application_count: allocationAmount,
        sectors: selectedSectors,
        education_level: selectedEducation,
        year_of_graduation: selectedGraduation,
        degree_subject: selectedDegree,
        gender: selectedGender ? selectedGender.value : null,
        use_location: useLocation
      },
      type: 'POST',
      dataType: 'json',
      success: (response) => {
        createToast({ type: 'success', text: 'Successfully allocated additional candidates', key })
        setIsSubmitting(false)
        handleSuccess()
      },
      error: (jqXhr) => {
        setIsSubmitting(false)
        createToast({
          text: ajaxErrorString(jqXhr),
          type: 'error',
        });
      }
    });
  }

  const handleSectorChange = (items) => setSelectedSectors(items.map((item) => item.value))

  const selectableSelectOptions = sectorOptions.map((option) => ({ value: option, label: option }))
  const selectedSelectOptions = selectableSelectOptions.filter((option) => selectedSectors.includes(option.value))

  const handleEducationChange = (items) => setSelectedEducation(items.map((item) => item.value))

  const programmeEducationOptions = educationOptions;

  const selectableEducationOptions = programmeEducationOptions.map((option) => ({ value: option, label: option }))
  const selectedEducationOptions = selectableEducationOptions.filter((option) => selectedEducation.includes(option.value))

  const handleGenderSelect = (item) => setSelectedGender(item)

  const selectableGraduationOptions = graduationOptions.map((option) => ({ value: option, label: option }))
  const selectedGraduationOptions = selectableGraduationOptions.filter((option) => selectedGraduation.includes(option.value))

  const handleGraduationChange = (items) => setSelectedGraduation(items.map((item) => item.value))

  const selectableDegreeSubjects = degree_subjects.map((option) => ({ value: option, label: option }))
  const selectedDegreeOptions = selectableDegreeSubjects.filter((option) => selectedDegree.includes(option.value))

  const handleDegreeChange = (items) => setSelectedDegree(items.map((item) => item.value))

  const handleLocationClick = () => setUseLocation(!useLocation)

  return(
    <Dialog title="Allocate candidates" isOpen={isOpen} onClose={handleClose}>
      <FormGroup label="Number of candidates">
        <Input type="number" min={1} onChange={handleNumberChange} value={allocationAmount} />
      </FormGroup>
      <FormGroup
        label="Workstreams"
        helperText="The candidates allocated candidates will be split evenly across the selected sectors. Leave this blank to use the jobs workstream"
      >
        <MultiSelect
          fill={true}
          items={selectableSelectOptions}
          selectedItems={selectedSelectOptions}
          handleChange={handleSectorChange}
          condensed={selectedSectors.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Restrict location" helperText="Check this if we should only assign candidates in the same location as the job">
        <Switch
          checked={useLocation}
          large={true}
          onClick={handleLocationClick}
        />
      </FormGroup>
      <FormGroup label="Level of education" helperText="Specify a levels of education">
        <MultiSelect
          fill={true}
          items={selectableEducationOptions}
          selectedItems={selectedEducationOptions}
          handleChange={handleEducationChange}
          condensed={selectedEducation.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Year of graduation" helperText="Specify a year of graduation">
        <MultiSelect
          fill={true}
          items={selectableGraduationOptions}
          selectedItems={selectedGraduationOptions}
          handleChange={handleGraduationChange}
          condensed={selectedGraduation.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Degree" helperText="Specify the degree subjects">
        <MultiSelect
          fill={true}
          items={selectableDegreeSubjects}
          selectedItems={selectedDegreeOptions}
          handleChange={handleDegreeChange}
          condensed={selectedDegree.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Gender">
        <Select
          onItemSelect={handleGenderSelect}
          items={[{ label: 'Female', value: 'Female' }, { label: 'Male', value: 'Male' }]}
          activeItem={selectedGender}
          fill={true}
          buttonProps={{ large: true }}
        />
      </FormGroup>
      <Button text="Allocate" fill={true} large={true} className="mar-t-3" onClick={handleClick} loading={isSubmitting} />
    </Dialog>
  )
}
