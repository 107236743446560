import {
  Body,
  Button,
  Card,
  CardLevel,
  Frow,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuItem,
  PaddingLevel,
  Popover,
  Subheading1,
  WorkflowTag,
} from '@pinpointhq/thumbtack';
import { format } from 'date-fns';
import * as React from 'react';
import ReactTable from 'react-table';
import { visit } from 'turbolinks';
import { exportAdminProgrammeReleaseRequestsPath } from '../../../../javascript/application/ts_routes';
import { ReleaseRequest } from '../../../../javascript/models';
import { createToast } from '../../../FlashToaster';
import Search from '../../../shared/Search';
import { useSpraypaintTableNamedParams } from '../../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../../shared/NewPaginationComponent';

export function ReleaseTable({ cycleId, programmeId }) {
  const identifier = 'released-applications';

  const { fetchData, tableProps, setQuery } = useSpraypaintTableNamedParams({
    initialScope: ReleaseRequest.includes([{ application: [{ job: ['company'] }, 'job_seeker'] }]),
    searchIdentifier: 'released-applications',
  });

  const key = 'release-confirmation';

  const columns = [
    {
      Cell: (row: any) => {
        return <div>{row.original.application.jobSeeker.fullName}</div>;
      },
      Header: 'Name',
      accessor: 'fullName',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.application.job.companyName}</div>;
      },
      Header: 'Company name',
      accessor: 'companyName',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.application.job.workstream}</div>;
      },
      Header: 'Workstream',
      accessor: 'workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{format(row.original.createdAt, 'MMMM Do YYYY')}</div>;
      },
      Header: 'Requested on',
      accessor: 'createdAt',
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.reason}</div>;
      },
      Header: 'Reason',
      accessor: 'releaseReason',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        if (row.original.status === 'accepted') {
          return <WorkflowTag intent={Intent.SUCCESS} text="Confirmed" />;
        }
        if (row.original.status === 'rejected') {
          return <WorkflowTag intent={Intent.DANGER} text="Rejected" />;
        }
        return <WorkflowTag intent={Intent.NONE} text="Unconfirmed" />;
      },
      Header: 'Status',
      accessor: 'status',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        const releaseRequest = row.original;
        const handleClick = () => {
          releaseRequest.status = 'accepted';
          releaseRequest.save().then((success: boolean) => {
            if (success) {
              createToast({ type: 'success', text: 'Release request confirmed', key });
              fetchData();
            }
          });
        };

        const handleRejectClick = () => {
          releaseRequest.status = 'rejected';
          releaseRequest.save().then((success: boolean) => {
            if (success) {
              createToast({ type: 'success', text: 'Release request rejected', key });
              fetchData();
            }
          });
        };

        if (releaseRequest === 'accepted' || releaseRequest === 'rejected') return null;

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem text="Confirm release" onClick={handleClick} />
                <MenuItem text="Reject release" onClick={handleRejectClick} />
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      sortable: false,
      width: 80,
    },
  ];

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const handleSearch = (searchTerm: string) => {
    setQuery({ search: searchTerm });
  };

  const handleExport = () => {
    visit(exportAdminProgrammeReleaseRequestsPath(programmeId, { cycle_id: cycleId }));
  };

  return (
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-2">
        <Subheading1>Requests to release</Subheading1>
        <Body muted={true} className="mar-t-1">
          Applications that have been requested to be released by companies
        </Body>
      </div>
      <div className="mar-b-2">
        <Frow gutters={16} verticalGutters={16}>
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} handleSearch={handleSearch} />
          </div>
          <div>
            <Button minimal={true} text="Export" large={true} onClick={handleExport} />
          </div>
        </Frow>
      </div>
      <ReactTable
        {...tableProps}
        columns={columns}
        PaginationComponent={NewPaginationComponent}
        getPaginationProps={paginationProps}
        className="-unthemed"
        minRows={1}
      />
    </Card>
  );
}
